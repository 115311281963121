import React from 'react'

const ContactForm = () => {
  return (
    <section className="bg-white opacity-85 rounded-3xl">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl font-extrabold text-center text-charcoal">
          Bize Ulaşın
        </h2>
        <p className="mb-8 lg:mb-16 text-center text-gray-900 sm:text-xl">
          Stilinizi bulmak konusunda bir sorun mu yaşıyorsunuz? Profesyonel
          değerlendirmeye mi ihtiyacınız var? Bizimle iletişime geçin!
        </p>
        <form action="#" className="space-y-8">
          <div>
            <label
              htmlFor="email"
              className="block mb-2 font-medium text-lg text-gray-900"
            >
              Mail Adresiniz
            </label>
            <input
              type="email"
              id="email"
              className="shadow-sm bg-gray-500 border border-gray-600 text-white  rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 placeholder-gray-300 shadow-sm-light"
              placeholder="name@gmail.com"
              required
            />
          </div>
          <div>
            <label
              htmlFor="subject"
              className="block mb-2 font-medium text-lg text-gray-900"
            >
              Konu
            </label>
            <input
              type="text"
              id="subject"
              className="block p-3 w-full  text-white bg-gray-500 rounded-lg border border-gray-600 shadow-sm focus:ring-primary-500 focus:border-primary-500 placeholder-gray-300 shadow-sm-light"
              placeholder="Size nasıl yardımcı olabileceğimizden bahsedin"
              required
            />
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block mb-2 font-medium text-lg text-gray-900"
            >
              Mesajınız
            </label>
            <textarea
              id="message"
              rows="6"
              className="block p-2.5 w-full  text-white bg-gray-500 rounded-lg shadow-sm border border-gray-600 focus:ring-primary-500 focus:border-primary-500 placeholder-gray-300"
              placeholder="Mesajınızı yazın..."
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-zinc-800 hover:bg-neutral-100 text-white hover:text-charcoal hover:outline-double font-semibold text-base md:text-lg transition duration-300 ease-in-out mx-auto px-3 md:px-4 py-1 md:py-6 rounded-lg h-9 md:h-10 flex items-center justify-center whitespace-nowrap flex-shrink-0"
          >
            Mesajı Gönder
          </button>
        </form>
      </div>
    </section>
  )
}

export default ContactForm
