import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { registerUser } from '../actions/authActions'
import { REMOVE_MESSAGE } from '../types/authTypes'
import toast from 'react-hot-toast'

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    confirmPassword: '',
    email: ''
  })

  const { message, loading, isAuthenticated } = useSelector(
    (state) => state.authReducer
  )

  const dispatch = useDispatch()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const { firstName, lastName, phone, password, confirmPassword, email } =
      formData

    if (!firstName || !lastName || !phone || !password || !email) {
      return toast.error('Tüm alanlar doldurulmalıdır.')
    }
    if (password.length < 6) {
      return toast.error('Şifre en az 6 karakter olmalıdır.')
    }
    if (password !== confirmPassword) {
      return toast.error('Şifreler eşleşmiyor.')
    }

    dispatch(registerUser({ firstName, lastName, phone, password, email }))
  }

  useEffect(() => {
    const messagesArray = Array.isArray(message) ? message : [message]
    if ({ message }.length > 0) {
      messagesArray.map((msg) => toast(msg, { icon: 'ℹ️' }))
    }
    dispatch({ type: REMOVE_MESSAGE })
  }, [dispatch, message])

  const navigate = useNavigate()
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/customer-dashboard')
    }
  }, [isAuthenticated, navigate])

  return (
    <div className="bg-zinc-800 p-6 md:p-20 lg:min-h-[calc(100vh-200px)]">
      <div className="w-full max-w-sm mx-auto overflow-hidden bg-zinc-800 rounded-lg shadow-md outline outline-1 outline-charcoal">
        <div className="px-8 py-4">
          <h3 className="my-6 text-2xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-sky-300 to-yellow-200">
            Hesap Oluştur
          </h3>

          <form onSubmit={handleSubmit}>
            <div className="mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                name="firstName"
                placeholder="Ad"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                name="lastName"
                placeholder="Soyad"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                name="phone"
                placeholder="Telefon"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                type="password"
                name="password"
                placeholder="Şifre"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <input
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                type="password"
                name="confirmPassword"
                placeholder="Şifre Tekrar"
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <input
                type="email"
                className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg p-2.5 placeholder-gray-300 shadow-sm-light"
                name="email"
                placeholder="Email"
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="block w-full px-4 py-2 mt-6 text-white font-semibold tracking-wide rounded-lg bg-gradient-to-r from-sky-500 to-orange-sky-700 hover:from-sky-400"
            >
              {loading ? 'Kayıt Yapılıyor...' : 'Kayıt Ol'}
            </button>
          </form>
        </div>
        <div className="flex items-center justify-center py-4 text-center bg-orange-300">
          <span className="text-sm text-zinc-800 font-medium">
            Şifrenizi not etmeyi unutmayınız.
          </span>
        </div>
      </div>
    </div>
  )
}

export default RegisterPage
