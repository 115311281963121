import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getEmployees,
  addEmployee,
  updateEmployee,
  deleteEmployee
} from '../../actions/Admin/adminEmployeeActions'

const EmployeeManagement = () => {
  const dispatch = useDispatch()
  const { employees, loading, error } = useSelector(
    (state) => state.adminReducer
  )

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: ''
  })
  const [editingEmployeeId, setEditingEmployeeId] = useState(null)

  useEffect(() => {
    dispatch(getEmployees())
  }, [dispatch])

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleUpdateEmployee = (employeeId) => {
    dispatch(updateEmployee(employeeId, formData))
    clearForm()
  }

  const handleDeleteEmployee = (employeeId) => {
    dispatch(deleteEmployee(employeeId))
  }

  const handleAddEmployee = () => {
    dispatch(addEmployee(formData))
    clearForm()
  }

  const clearForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: ''
    })
    setEditingEmployeeId(null)
  }

  const handleEditClick = (employee) => {
    setEditingEmployeeId(employee._id)
    setFormData({
      firstName: employee.firstName,
      lastName: employee.lastName,
      phone: employee.phone,
      email: employee.email,
      password: ''
    })
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-center">Çalışan Yönetimi</h2>
      {loading && <p className="text-center text-gray-500">Yükleniyor...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}
      {employees && employees.length > 0 ? (
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">İsim</th>
              <th className="py-2 px-4 border-b">Soyisim</th>
              <th className="py-2 px-4 border-b">Telefon</th>
              <th className="py-2 px-4 border-b">Email</th>
              <th className="py-2 px-4 border-b">İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee) => (
              <tr key={employee._id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b">{employee.firstName}</td>
                <td className="py-2 px-4 border-b">{employee.lastName}</td>
                <td className="py-2 px-4 border-b">{employee.phone}</td>
                <td className="py-2 px-4 border-b">{employee.email}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => handleEditClick(employee)}
                    className="bg-blue-500 text-white px-4 py-2 rounded mr-2 hover:bg-blue-600"
                  >
                    Güncelle
                  </button>
                  <button
                    onClick={() => handleDeleteEmployee(employee._id)}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    Sil
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-center text-gray-500">Hiç çalışan bulunamadı.</p>
      )}

      <h3 className="text-xl font-semibold mt-6 mb-4 text-center">
        {editingEmployeeId ? 'Çalışan Güncelle' : 'Yeni Çalışan Ekle'}
      </h3>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          editingEmployeeId
            ? handleUpdateEmployee(editingEmployeeId)
            : handleAddEmployee()
        }}
        className="space-y-4"
      >
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          placeholder="İsim"
          required
          className="w-full px-4 py-2 border rounded"
        />
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          placeholder="Soyisim"
          required
          className="w-full px-4 py-2 border rounded"
        />
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          placeholder="Telefon"
          required
          className="w-full px-4 py-2 border rounded"
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Email"
          className="w-full px-4 py-2 border rounded"
        />
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          placeholder="Şifre"
          required={!editingEmployeeId}
          className="w-full px-4 py-2 border rounded"
        />
        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            {editingEmployeeId ? 'Çalışanı Güncelle' : 'Yeni Çalışan Ekle'}
          </button>
          <button
            type="button"
            onClick={clearForm}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Formu Temizle
          </button>
        </div>
      </form>
    </div>
  )
}

export default EmployeeManagement
