import React from 'react'

const PhotoGallery = () => {
  const photos = [
    'gallery-1.webp',
    'gallery-2.webp',
    'gallery-3.webp',
    'gallery-4.webp',
    'gallery-5.webp',
    'gallery-6.webp',
    'gallery-7.webp',
    'gallery-8.webp'
  ]

  return (
    <div className="relative max-w-full mx-auto shadow-overlay">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-">
        {photos.map((photo, index) => (
          <div key={index} className="w-full h-64 bg-gray-200">
            <img
              src={photo}
              alt={`Fotoğraf ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PhotoGallery
