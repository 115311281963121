import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAppointments } from '../actions/appointmentActions'
import { format, addMonths, subMonths } from 'date-fns'
import { tr } from 'date-fns/locale'

const Calendar = () => {
  const dispatch = useDispatch()
  const { appointments } = useSelector((state) => state.appointmentReducer)
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(null)

  useEffect(() => {
    dispatch(getAppointments())
  }, [dispatch])

  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  )
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  )
  const daysInMonth = Array.from(
    { length: endOfMonth.getDate() },
    (_, i) => i + 1
  )

  const handlePrevMonth = () => setCurrentDate(subMonths(currentDate, 1))
  const handleNextMonth = () => setCurrentDate(addMonths(currentDate, 1))

  const handleDayClick = (day) =>
    setSelectedDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
    )

  const renderAppointmentsForDay = (day) => {
    const dayAppointments = appointments.filter(
      (appointment) =>
        new Date(appointment.date).getDate() === day &&
        new Date(appointment.date).getMonth() === currentDate.getMonth() &&
        new Date(appointment.date).getFullYear() === currentDate.getFullYear()
    )

    const isDayFullyBooked = dayAppointments.length === 18

    return (
      <div
        key={day}
        onClick={() => handleDayClick(day)}
        className={`p-4 border rounded-lg cursor-pointer transition-colors duration-300 ease-in-out ${
          isDayFullyBooked
            ? 'bg-red-600 text-white'
            : 'bg-white hover:bg-gray-200'
        }`}
      >
        <span
          className={`font-bold ${
            isDayFullyBooked ? 'text-white' : 'text-gray-800'
          }`}
        >
          {day}
        </span>
      </div>
    )
  }

  const renderTimeSlots = () => {
    const timeSlots = [
      '09:00-09:30',
      '09:30-10:00',
      '10:00-10:30',
      '10:30-11:00',
      '11:00-11:30',
      '11:30-12:00',
      '12:00-12:30',
      '12:30-13:00',
      '13:00-13:30',
      '13:30-14:00',
      '14:00-14:30',
      '14:30-15:00',
      '15:00-15:30',
      '15:30-16:00',
      '16:00-16:30',
      '16:30-17:00',
      '17:00-17:30',
      '17:30-18:00',
      '18:00-18:30'
    ]

    if (!selectedDate)
      return (
        <p className="text-center font-bold text-gray-500">
          Gün seçimi yapınız.
        </p>
      )

    const dayAppointments = appointments.filter(
      (appointment) =>
        new Date(appointment.date).getDate() === selectedDate.getDate() &&
        new Date(appointment.date).getMonth() === selectedDate.getMonth() &&
        new Date(appointment.date).getFullYear() === selectedDate.getFullYear()
    )

    return (
      <div className="grid grid-cols-2 gap-4 mt-4">
        {timeSlots.map((slot) => {
          const isSlotBooked = dayAppointments.some(
            (appointment) => appointment.timeSlot === slot
          )
          return (
            <div
              key={slot}
              className={`p-2 border rounded-lg text-center cursor-pointer transition-colors duration-300 ease-in-out ${
                isSlotBooked
                  ? 'bg-red-600 text-white'
                  : 'bg-green-500 text-white'
              }`}
            >
              {slot}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-50 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={handlePrevMonth}
          className="text-2xl font-bold text-gray-700 hover:text-gray-900"
        >
          &lt;
        </button>
        <h2 className="text-3xl font-semibold">
          {format(currentDate, 'MMMM yyyy', { locale: tr })}
        </h2>
        <button
          onClick={handleNextMonth}
          className="text-2xl font-bold text-gray-700 hover:text-gray-900"
        >
          &gt;
        </button>
      </div>
      <div className="grid grid-cols-7 gap-4 mb-4 text-center font-semibold">
        {['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'].map((day) => (
          <div key={day}>{day}</div>
        ))}
        {daysInMonth.map((day) => renderAppointmentsForDay(day))}
      </div>
      {renderTimeSlots()}
    </div>
  )
}

export default Calendar
