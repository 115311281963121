import axiosInstance from '../utils/axiosInstance'
import {
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_REQUEST
} from '../types/employeeTypes'

export const getEmployees = () => async (dispatch) => {
  try {
    dispatch({ type: GET_EMPLOYEES_REQUEST })

    const { data } = await axiosInstance.get('/employees')
    dispatch({ type: GET_EMPLOYEES_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Çalışanlar Listelenirken bir hata oluştu.'
    dispatch({
      type: GET_EMPLOYEES_FAILURE,
      payload: message
    })
  }
}
