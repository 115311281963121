import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAppointments,
  cancelAppointment
} from '../actions/appointmentActions'
import EmployeeAppointmentList from '../components/EmployeeAppointmentList'

const EmployeeDashboard = () => {
  const dispatch = useDispatch()
  const { appointments } = useSelector((state) => state.appointmentReducer)
  const { user } = useSelector((state) => state.authReducer)

  useEffect(() => {
    if (user) {
      dispatch(getAppointments())
    }
  }, [dispatch, user])

  const handleCancel = async (appointmentId) => {
    await cancelAppointment(appointmentId)
    dispatch(getAppointments())
  }

  return (
    <div className="max-w-xl mx-auto p-4">
      <h1 className="text-3xl font-bold text-center my-4">Çalışan Paneli</h1>
      {appointments && appointments.length > 0 ? (
        <EmployeeAppointmentList
          appointments={appointments}
          onCancel={handleCancel}
        />
      ) : (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <p className="text-xl font-semibold text-center">
            <span className="mr-1">🧔🏻</span>Görüntülenecek Randevu Bulunmuyor..
          </p>
        </div>
      )}
    </div>
  )
}

export default EmployeeDashboard
