import axiosInstance from '../../utils/axiosInstance'
import {
  ADMIN_GET_CUSTOMERS_REQUEST,
  ADMIN_GET_CUSTOMERS_SUCCESS,
  ADMIN_GET_CUSTOMERS_FAILURE,
  ADMIN_UPDATE_CUSTOMER_REQUEST,
  ADMIN_UPDATE_CUSTOMER_SUCCESS,
  ADMIN_UPDATE_CUSTOMER_FAILURE,
  ADMIN_DELETE_CUSTOMER_REQUEST,
  ADMIN_DELETE_CUSTOMER_SUCCESS,
  ADMIN_DELETE_CUSTOMER_FAILURE
} from '../../types/adminTypes'

export const getCustomers = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_GET_CUSTOMERS_REQUEST })
    const { data } = await axiosInstance.get('/admin/customers')

    dispatch({ type: ADMIN_GET_CUSTOMERS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Müşteriler listelenirken bir hata oldu.'
    dispatch({
      type: ADMIN_GET_CUSTOMERS_FAILURE,
      payload: message
    })
  }
}

export const updateCustomer =
  (customerId, customerData) => async (dispatch) => {
    try {
      dispatch({ type: ADMIN_UPDATE_CUSTOMER_REQUEST })
      const { data } = await axiosInstance.patch(
        `/admin/customers/${customerId}`,
        customerData
      )
      dispatch({ type: ADMIN_UPDATE_CUSTOMER_SUCCESS, payload: data })
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Müşteri güncellenirken bir hata oldu.'
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_FAILURE,
        payload: message
      })
    }
  }

export const deleteCustomer = (customerId) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_DELETE_CUSTOMER_REQUEST })
    await axiosInstance.delete(`/admin/customers/${customerId}`)
    dispatch({ type: ADMIN_DELETE_CUSTOMER_SUCCESS, payload: customerId })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Müşteri silinirken bir hata oldu.'
    dispatch({
      type: ADMIN_DELETE_CUSTOMER_FAILURE,
      payload: message
    })
  }
}
