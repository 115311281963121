import React from 'react'
import HairStylesGrid from '../components/HairStylesGrid'

const HairPage = () => {
  return (
    <div className="min-h-screen flex flex-col tracking-wide items-center justify-center bg-zinc-800 p-4">
      <main className="flex-1 mx-auto px-4 py-8">
        <section className="text-center mb-12">
          <h2 className="text-3xl tracking-wide font-bold text-neutral-50 rounded-b-3xl p-4 bg-zinc-800 drop-shadow-xl mb-4 shadow-custom-equal border-b-2 border-x-2 border-charcoal">
            STİLLER
          </h2>

          {/* <p className="text-lg text-neutral-100">
            Unicut Hair Design'da en iyi saç tasarımı, kesimi ve bakım
            hizmetlerini sunuyoruz.
          </p> */}
        </section>

        <section className="mb-12">
          <HairStylesGrid />
        </section>
      </main>
    </div>
  )
}

export default HairPage
