import React from 'react'
import { Link } from 'react-router-dom'

const AdminDashboard = () => {
  return (
    <div>
      <h1>Admin Paneli</h1>
      <ul>
        <li>
          <Link to="/admin-dashboard/employees">Çalışan Yönetimi</Link>
        </li>
        <li>
          <Link to="/admin-dashboard/customers">Müşteri Yönetimi</Link>
        </li>
        <li>
          <Link to="/admin-dashboard/appointments">Randevu Yönetimi</Link>
        </li>
      </ul>
    </div>
  )
}

export default AdminDashboard
