import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAppointments,
  cancelAppointment,
  deleteAppointment
} from '../../actions/Admin/adminAppointmentActions'
import { getEmployees } from '../../actions/Admin/adminEmployeeActions'

const AppointmentManagement = () => {
  const dispatch = useDispatch()
  const { employees, appointments, loading, error } = useSelector(
    (state) => state.adminReducer
  )

  const [selectedEmployee, setSelectedEmployee] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredAppointments, setFilteredAppointments] = useState([])
  const [selectedDate, setSelectedDate] = useState('')

  useEffect(() => {
    dispatch(getEmployees())
  }, [dispatch])

  useEffect(() => {
    dispatch(getAppointments(''))
  }, [dispatch])

  useEffect(() => {
    if (appointments) {
      let filtered = appointments

      if (selectedDate) {
        filtered = filtered.filter(
          (appointment) =>
            new Date(appointment.date).toLocaleDateString() ===
            new Date(selectedDate).toLocaleDateString()
        )
      }

      if (searchQuery) {
        filtered = filtered.filter(
          (appointment) =>
            appointment.customer.firstName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            appointment.customer.lastName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            appointment.customer.phone.includes(searchQuery)
        )
      }

      filtered = filtered.sort((a, b) => new Date(b.date) - new Date(a.date))

      setFilteredAppointments(filtered)
    }
  }, [appointments, searchQuery, selectedDate])

  const handleEmployeeSelect = (e) => {
    setSelectedEmployee(e.target.value)
    dispatch(getAppointments(e.target.value))
  }

  const handleCancelAppointment = (appointmentId) => {
    dispatch(cancelAppointment(appointmentId))
  }

  const handleDeleteAppointment = (appointmentId) => {
    dispatch(deleteAppointment(appointmentId))
  }

  const getStatusText = (status) => {
    switch (status) {
      case 'Cancelled':
        return 'İptal'
      case 'Scheduled':
        return 'Aktif'
      case 'Completed':
        return 'OK'
      default:
        return status
    }
  }

  const formatEmployeeName = (firstName, lastName) => {
    return `${firstName.charAt(0)}. ${lastName.charAt(0)}.`
  }

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-center">Randevu Yönetimi</h2>

      {loading && <p className="text-center text-gray-500">Yükleniyor...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}

      {/* Çalışan Seçimi */}
      <select
        value={selectedEmployee}
        onChange={handleEmployeeSelect}
        className="w-full p-2 mb-4 border rounded"
      >
        <option value="">Tüm Çalışanlar</option>
        {employees.map((employee) => (
          <option key={employee._id} value={employee._id}>
            {employee.firstName} {employee.lastName}
          </option>
        ))}
      </select>

      {/* Tarih Seçimi */}
      <input
        type="date"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        className="w-full p-2 mb-4 border rounded"
      />

      {/* Arama Kutusu */}
      <input
        type="text"
        placeholder="Müşteri Ad, Soyad veya Telefon ile ara"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="w-full p-2 mb-4 border rounded"
      />

      {/* Randevu Tablosu */}
      {filteredAppointments.length > 0 ? (
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Tarih</th>
              <th className="py-2 px-4 border-b">Saat</th>
              <th className="py-2 px-4 border-b">Müşteri</th>
              <th className="py-2 px-4 border-b">Telefon</th>
              <th className="py-2 border-b">Çalışan</th>
              <th className="py-2 px-1 border-b">Durum</th>
              <th className="py-2 border-b">İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {filteredAppointments.map((appointment) => (
              <tr key={appointment._id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b text-center">
                  {new Date(appointment.date).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {appointment.timeSlot}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {appointment.customer.firstName}{' '}
                  {appointment.customer.lastName}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {appointment.customer.phone}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {formatEmployeeName(
                    appointment.employee.firstName,
                    appointment.employee.lastName
                  )}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {getStatusText(appointment.status)}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  <button
                    onClick={() => handleCancelAppointment(appointment._id)}
                    disabled={appointment.status === 'Cancelled'}
                    className={`${
                      appointment.status === 'Cancelled' ||
                      appointment.status === 'Completed'
                        ? 'bg-gray-500 text-white cursor-not-allowed'
                        : 'bg-yellow-500 text-white hover:bg-yellow-600 cursor-pointer'
                    } px-4 py-2 rounded mr-2`}
                  >
                    İptal Et
                  </button>

                  <button
                    onClick={() => handleDeleteAppointment(appointment._id)}
                    className="bg-red-500 text-white px-4 py-2 my-1 rounded hover:bg-red-600"
                  >
                    Sil
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-center text-gray-500">Hiç randevu bulunamadı.</p>
      )}
    </div>
  )
}

export default AppointmentManagement
