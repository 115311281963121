import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from 'react-router-dom'
import { setCurrentUser } from './actions/authActions'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'
import InfoPage from './pages/InfoPage'
import HairPage from './pages/HairPage'
import AdminDashboard from './pages/AdminDashboard'
import EmployeeDashboard from './pages/EmployeeDashboard'
import CustomerDashboard from './pages/CustomerDashboard'
import NotFound from './components/NotFound'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import EmployeeManagement from './components/Admin/EmployeeManagement'
import CustomerManagement from './components/Admin/CustomerManagement'
import AppointmentManagement from './components/Admin/AppointmentManagement'
import PrivateRoute from './components/PrivateRoute'
import { Toaster } from 'react-hot-toast'

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const token = localStorage.getItem('myToken')
    if (token) {
      dispatch(setCurrentUser(token))
    }
  }, [dispatch, setCurrentUser])

  return (
    <Router>
      <Navbar />
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/hakkimizda" element={<InfoPage />} />
        <Route path="/stiller" element={<HairPage />} />
        <Route
          path="/customer-dashboard"
          element={
            <PrivateRoute>
              <CustomerDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/employee-dashboard"
          element={
            <PrivateRoute>
              <EmployeeDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-dashboard"
          element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-dashboard/employees"
          element={
            <PrivateRoute>
              <EmployeeManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-dashboard/customers"
          element={
            <PrivateRoute>
              <CustomerManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin-dashboard/appointments"
          element={
            <PrivateRoute>
              <AppointmentManagement />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      {/* <FooterWrapper /> */}
    </Router>
  )
}

export default App
