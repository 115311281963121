import React from 'react'
import { FaFacebookF, FaTwitter, FaInstagram, FaPhoneAlt } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className="bg-charcoal text-gray-400 pt-14">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center lg:items-start px-4 md:px-8">
        {/* Logo ve Açıklama */}
        <div className="flex flex-col items-center mb-8 lg:mb-0 text-center lg:text-left">
          <div className="text-white text-lg font-semibold">
            <img
              src="logoFooter.webp"
              alt="UNICUT HAIR DESIGN"
              className="h-44"
            />
          </div>
          <p className="text-gray-400 my-5">
            En iyi saç kesimi ve bakım hizmetleri
          </p>
        </div>

        {/* Çalışma Saatleri */}
        <div className="mx-auto lg:mx-0 text-center lg:text-left flex flex-col items-center mb-8 lg:mb-0 lg:mr-8">
          <h2 className="text-2xl font-bold my-4">Çalışma Saatlerimiz</h2>
          <div className="space-y-2 max-w-48 text-sm md:text-base">
            {[
              'Pazartesi',
              'Salı',
              'Çarşamba',
              'Perşembe',
              'Cuma',
              'Cumartesi',
              'Pazar'
            ].map((day, index) => (
              <div key={index} className="flex justify-between space-x-6">
                <span>{day}:</span>
                <span>{day === 'Pazar' ? 'Kapalı' : '10.00 - 23.00'}</span>
              </div>
            ))}
          </div>
        </div>

        {/* İletişim Bilgileri */}
        <div className="text-center lg:text-left flex flex-col items-center mb-8 lg:mb-0">
          <h2 className="text-2xl font-bold my-4">İletişim</h2>
          <div className="space-y-2">
            <div className="flex items-center mb-4">
              <FaPhoneAlt className="mr-2" />
              <span>+90 555 555 5555</span>
            </div>
            <div className="flex justify-center space-x-6 text-xl">
              <a
                href="https://facebook.com"
                className="text-gray-400 hover:text-white"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://twitter.com"
                className="text-gray-400 hover:text-white"
              >
                <FaTwitter />
              </a>
              <a
                href="https://instagram.com"
                className="text-gray-400 hover:text-white"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>

        {/* Harita */}
        <div className="w-full lg:w-2/5 xl:w-1/3 mt-8 lg:mt-10">
          <div className="relative" style={{ paddingBottom: '56.25%' }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3066.142545930761!2d30.5137005!3d39.7813563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cc153482a4f2f5%3A0x3f130feadc8fe240!2sUnicut%20Hair%20Design!5e0!3m2!1str!2str!4v1725909680373!5m2!1str!2str"
              className="absolute top-0 left-0 w-full h-full border-0"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="bg-footerGray text-center text-gray-400 py-6 mt-6">
        © 2024 UNICUT HAIR DESIGN. Tüm hakları saklıdır.
      </div>
    </footer>
  )
}

export default Footer
