import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { tr } from 'date-fns/locale'
import moment from 'moment-timezone'

const EmployeeAppointmentList = ({ appointments, onCancel }) => {
  const [disabledButtons, setDisabledButtons] = useState({})

  useEffect(() => {
    const newDisabledButtons = {}
    appointments.forEach((appointment) => {
      const appointmentDateTime = moment(appointment.date)
      appointmentDateTime.set('hour', appointment.timeSlot.split(':')[0])
      appointmentDateTime.set('minute', appointment.timeSlot.split(':')[1])
      appointmentDateTime.set('second', 0)
      appointmentDateTime.set('millisecond', 0)

      const now = moment.tz('Europe/Istanbul')
      const differenceInMinutes = appointmentDateTime.diff(now, 'minutes')

      newDisabledButtons[appointment._id] = differenceInMinutes <= 60
    })
    setDisabledButtons(newDisabledButtons)
  }, [appointments])

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-center text-cyan-600">
        Randevular
      </h2>
      {appointments.length === 0 ? (
        <p className="text-xl font-semibold text-center">
          <span className="mr-1">😔</span>Görüntülenecek Randevu Bulunmuyor..
        </p>
      ) : (
        <ul className="space-y-4">
          {appointments.map((appointment) => (
            <li
              key={appointment._id}
              className="p-4 border rounded-lg shadow-sm bg-gray-50"
            >
              <p className="text-lg font-semibold">
                <span className="text-xl mr-1">📅</span>
                {format(new Date(appointment.date), 'dd/MM/yyyy')}{' '}
                <span className="text-xl mx-2">⁃</span>
                <span className="text-xl mr-1">🕒</span>
                {appointment.timeSlot}
              </p>
              <p className="text-lg font-semibold">
                <span className="text-xl mr-1">👥</span>
                {appointment.customer.firstName} {appointment.customer.lastName}{' '}
                <span className="text-xl mx-2">⁃</span>
                <span className="text-xl">📱</span>
                <a
                  href={`tel:${appointment.employee.phone}`}
                  className="relative text-indigo-600 after:absolute after:left-0 after:bottom-0 after:h-[2px] after:w-full after:scale-x-0 after:bg-indigo-600 after:transition-transform after:duration-300 hover:after:scale-x-100"
                >
                  {appointment.customer.phone}
                </a>
              </p>
              <p className="text-lg font-semibold">
                <span className="text-xl mr-1">👀</span>Geçerlilik{' '}
                <span className="text-xl mx-2">⁃</span>
                {appointment.status === 'Scheduled'
                  ? '🟢 Randevu Aktif'
                  : appointment.status === 'Completed'
                  ? 'Tamamlandı ✅'
                  : '❌ İptal Edildi'}
              </p>
              <button
                onClick={() => onCancel(appointment._id)}
                disabled={
                  disabledButtons[appointment._id] ||
                  appointment.status === 'Cancelled'
                }
                className={`mt-2 py-1 px-3 text-md font-semibold rounded  ${
                  disabledButtons[appointment._id] ||
                  appointment.status === 'Cancelled'
                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    : 'bg-red-500 text-white hover:bg-red-600 transition duration-200'
                }`}
              >
                {appointment.status === 'Cancelled' || 'Completed'
                  ? 'Randevu İptal Et'
                  : 'Randevu İptal Et'}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default EmployeeAppointmentList
