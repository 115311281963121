export const CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST'
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS'
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE'
export const GET_APPOINTMENTS_REQUEST = 'GET_APPOINTMENTS_REQUEST'
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS'
export const GET_APPOINTMENTS_FAILURE = 'GET_APPOINTMENTS_FAILURE'
export const UPDATE_APPOINTMENT_REQUEST = 'UPDATE_APPOINTMENT_REQUEST'
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS'
export const UPDATE_APPOINTMENT_FAILURE = 'UPDATE_APPOINTMENT_FAILURE'
export const DELETE_APPOINTMENT_REQUEST = 'DELETE_APPOINTMENT_REQUEST'
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS'
export const DELETE_APPOINTMENT_FAILURE = 'DELETE_APPOINTMENT_FAILURE'

export const SET_MESSAGE = 'SET_MESSAGE'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
