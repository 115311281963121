import React, { useState, useEffect, useRef } from 'react'

const Counter = ({ end, duration }) => {
  const [count, setCount] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const counterRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.disconnect()
        }
      },
      { threshold: 0.1 }
    )

    if (counterRef.current) {
      observer.observe(counterRef.current)
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!isVisible) return

    let start = 0
    const increment = end / ((duration / 1000) * 300)
    const timer = setInterval(() => {
      start += increment
      if (start >= end) {
        setCount(end)
        clearInterval(timer)
      } else {
        setCount(Math.ceil(start))
      }
    }, 1000 / 300)

    return () => clearInterval(timer)
  }, [isVisible, end, duration])

  return (
    <div ref={counterRef} className="text-5xl font-bold text-emerald-400">
      {count}
    </div>
  )
}

export default Counter
