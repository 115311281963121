import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { logoutUser } from '../actions/authActions'

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const auth = useSelector((state) => state.authReducer)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const handleLogout = () => {
    dispatch(logoutUser())
    navigate('/')
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <nav className="bg-charcoal p-2 shadow-lg">
      <div className="container mx-auto flex md:justify-center items-center relative">
        {/* Hamburger Menü (Mobil Görünüm) */}
        <div className="sm:absolute left-0 flex items-center space-x-4 lg:hidden mr-4 sm:mr-2 ml-2">
          <button onClick={toggleMenu} className="focus:outline-none z-50">
            {/* Hamburger menü animasyonu */}
            <div
              className={`relative w-6 h-6 transition-all duration-300 ease-in-out ${
                isMenuOpen ? 'transform rotate-45' : ''
              }`}
            >
              <span
                className={`block w-full h-0.5 bg-white mb-1 transform transition duration-300 ${
                  isMenuOpen ? 'rotate-45 translate-y-1.5' : ''
                }`}
              />
              <span
                className={`block w-full h-0.5 bg-white mb-1 transition-all duration-300 ${
                  isMenuOpen ? 'opacity-0' : ''
                }`}
              />
              <span
                className={`block w-full h-0.5 bg-white transition duration-300 ${
                  isMenuOpen ? '-rotate-45 -translate-y-1.5' : ''
                }`}
              />
            </div>
          </button>
        </div>

        {/* Navbar Linkleri (Masaüstü Görünüm) */}
        <ul className="hidden lg:flex space-x-6 absolute left-0">
          <li>
            <Link
              className="p-4 cursor-pointer text-white hover:text-red-300 font-semibold text-xl transition duration-300 ease-in-out"
              to="/"
            >
              Anasayfa
            </Link>
          </li>
          <li>
            <Link
              className="p-4 cursor-pointer text-white hover:text-red-300 font-semibold text-xl transition duration-300 ease-in-out"
              to="/hakkimizda"
            >
              Hakkımızda
            </Link>
          </li>
          <li>
            <Link
              className="p-4 cursor-pointer text-white hover:text-red-300 font-semibold text-xl transition duration-300 ease-in-out"
              to="/stiller"
            >
              Stiller
            </Link>
          </li>
        </ul>

        {/* Logo (Her zaman ortada) */}
        <div className="text-white text-lg font-semibold sm:mx-auto">
          <Link to="/">
            <img
              src="logoUzunNav.webp"
              alt="UNICUT HAIR DESIGN"
              className="h-11 md:h-20"
            />
          </Link>
        </div>

        {/* Sağdaki Menü (Her zaman en sağda) */}
        <div className="absolute right-0 flex space-x-2 md:space-x-4">
          {auth.isAuthenticated ? (
            <>
              {auth.user.role === 'Admin' && (
                <Link
                  className="bg-white hover:bg-blue-500 hover:text-white font-semibold text-base md:text-lg transition duration-300 ease-in-out px-3 md:px-4 py-1 rounded mx-1 md:mx-2 h-8 md:h-10 flex items-center justify-center"
                  to="/admin-dashboard"
                >
                  Admin Paneli
                </Link>
              )}
              {auth.user.role === 'Employee' && (
                <Link
                  className="bg-white hover:bg-blue-500 hover:text-white font-semibold text-base md:text-lg transition duration-300 ease-in-out px-3 md:px-4 py-1 rounded mx-1 md:mx-2 h-8 md:h-10 flex items-center justify-center"
                  to="/employee-dashboard"
                >
                  Çalışan Paneli
                </Link>
              )}
              {auth.user.role === 'Customer' && (
                <Link
                  className="bg-white hover:bg-blue-500 hover:text-white font-semibold text-base md:text-lg transition duration-300 ease-in-out px-3 md:px-4 py-1 rounded mx-1 md:mx-2 h-8 md:h-10 flex items-center justify-center"
                  to="/customer-dashboard"
                >
                  Müşteri Paneli
                </Link>
              )}
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white font-semibold text-base md:text-lg transition duration-300 ease-in-out px-3 md:px-4 py-1 rounded ml-2 md:ml-4 hover:bg-red-600 h-8 md:h-10 flex items-center justify-center shadow-md"
              >
                Çıkış
              </button>
            </>
          ) : (
            <>
              <Link
                className="bg-white hover:bg-charcoal hover:text-white hover:outline-double font-semibold text-base md:text-lg transition duration-300 ease-in-out px-3 md:px-4 py-1 md:py-6 rounded-lg mx-1 md:mx-2 h-9 md:h-10 flex items-center justify-center whitespace-nowrap flex-shrink-0"
                to="/login"
              >
                Giriş Yap
              </Link>
              <Link
                className="bg-white hover:bg-charcoal hover:text-white hover:outline-double font-semibold text-base md:text-lg transition duration-300 ease-in-out px-3 md:px-4 py-1 md:py-6 rounded-lg md:mx-2 h-9 md:h-10 flex items-center justify-center whitespace-nowrap flex-shrink-0"
                to="/register"
              >
                Kayıt Ol
              </Link>
            </>
          )}
        </div>

        {/* Hamburger Menü (Açıkken) */}
        {isMenuOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center space-y-6 z-40 lg:hidden">
            <Link className="text-white text-2xl" to="/" onClick={toggleMenu}>
              Anasayfa
            </Link>
            <Link
              className="text-white text-2xl"
              to="/hakkimizda"
              onClick={toggleMenu}
            >
              Hakkımızda
            </Link>
            <Link
              className="text-white text-2xl"
              to="/stiller"
              onClick={toggleMenu}
            >
              Stiller
            </Link>
            <button
              onClick={toggleMenu}
              className="text-red-500 text-xl hover:text-red-300"
            >
              Kapat
            </button>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Navbar
