import {
  legacy_createStore as createStore,
  applyMiddleware,
  combineReducers
} from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import { thunk } from 'redux-thunk'
import authReducer from '../reducers/authReducer'
import appointmentReducer from '../reducers/appointmentReducer'

import employeeReducer from '../reducers/employeeReducer'
import adminReducer from '../reducers/adminReducer'

const rootReducers = combineReducers({
  authReducer,
  appointmentReducer,

  employeeReducer,
  adminReducer
})

const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(thunk))
)

export default store
