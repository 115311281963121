import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'

const teamMembers = [
  {
    name: 'Umut Yılmaz',
    role: 'Takım Üyesi',
    img: 'team-member-1.webp'
  },
  {
    name: 'Burak Aslan',
    role: 'Takım Üyesi',
    img: 'team-member-2.webp'
  },
  {
    name: 'Mustafa Sakaloğlu',
    role: 'Takım Üyesi',
    img: 'team-member-3.webp'
  },
  {
    name: 'Deniz Bıyıklı',
    role: 'Takım Üyesi',
    img: 'team-member-4.webp'
  }
]

const repeatedMembers = [...teamMembers, ...teamMembers]

const TeamCarousel = () => {
  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{ delay: 1500 }}
      loop={true}
      speed={2000}
      breakpoints={{
        640: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        1024: { slidesPerView: 4 }
      }}
    >
      {repeatedMembers.map((member, index) => (
        <SwiperSlide key={index}>
          <div className="flex flex-col px-1">
            <a className="mx-auto">
              <img
                className="rounded-2xl drop-shadow-md hover:drop-shadow-xl transition-all duration-200 delay-100"
                src={member.img}
                alt={member.name}
              />
            </a>
            <div className="text-center mt-6">
              <h1 className="text-neutral-200 text-xl font-bold mb-1">
                {member.name}
              </h1>
              <div className="text-neutral-200 font-medium">{member.role}</div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default TeamCarousel
