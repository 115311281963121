import axiosInstance from '../../utils/axiosInstance'
import {
  ADMIN_GET_APPOINTMENTS_REQUEST,
  ADMIN_GET_APPOINTMENTS_SUCCESS,
  ADMIN_GET_APPOINTMENTS_FAILURE,
  ADMIN_CANCEL_APPOINTMENT_REQUEST,
  ADMIN_CANCEL_APPOINTMENT_SUCCESS,
  ADMIN_CANCEL_APPOINTMENT_FAILURE,
  ADMIN_DELETE_APPOINTMENT_REQUEST,
  ADMIN_DELETE_APPOINTMENT_SUCCESS,
  ADMIN_DELETE_APPOINTMENT_FAILURE
} from '../../types/adminTypes'

export const getAppointments = (employeeId) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_GET_APPOINTMENTS_REQUEST })

    const url = employeeId
      ? `/admin/appointments/employee/${employeeId}`
      : '/admin/appointments'

    const { data } = await axiosInstance.get(url)
    dispatch({ type: ADMIN_GET_APPOINTMENTS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Randevular listelenirken bir hata oldu.'
    dispatch({
      type: ADMIN_GET_APPOINTMENTS_FAILURE,
      payload: message
    })
  }
}

export const cancelAppointment = (appointmentId) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_CANCEL_APPOINTMENT_REQUEST })
    await axiosInstance.patch(`/admin/appointments/${appointmentId}/cancel`)
    dispatch({ type: ADMIN_CANCEL_APPOINTMENT_SUCCESS, payload: appointmentId })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Randevu iptal edilirken bir hata oldu.'
    dispatch({
      type: ADMIN_CANCEL_APPOINTMENT_FAILURE,
      payload: message
    })
  }
}

export const deleteAppointment = (appointmentId) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_DELETE_APPOINTMENT_REQUEST })
    await axiosInstance.delete(`/admin/appointments/${appointmentId}`)
    dispatch({ type: ADMIN_DELETE_APPOINTMENT_SUCCESS, payload: appointmentId })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Randevu silinirken bir hata oldu.'
    dispatch({
      type: ADMIN_DELETE_APPOINTMENT_FAILURE,
      payload: message
    })
  }
}
