import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAppointments,
  cancelAppointment
} from '../actions/appointmentActions'
import { getEmployees } from '../actions/employeeActions'
import AppointmentForm from '../components/AppointmentForm'
import CustomerAppointmentList from '../components/CustomerAppointmentList'
import Calendar from '../components/Calendar'
import { REMOVE_MESSAGE } from '../types/authTypes'
import toast from 'react-hot-toast'

const CustomerDashboard = () => {
  const dispatch = useDispatch()
  const { message = [] } = useSelector(
    (state) => state.appointmentReducer || {}
  )

  const { user } = useSelector((state) => state.authReducer)
  const [activeTab, setActiveTab] = useState('create')

  useEffect(() => {
    if (user) {
      dispatch(getAppointments())
    }
  }, [dispatch, user])

  useEffect(() => {
    dispatch(getEmployees())
  }, [dispatch])

  const handleCancel = async (appointmentId) => {
    await cancelAppointment(appointmentId)
    dispatch(getAppointments())
  }

  useEffect(() => {
    const messagesArray = Array.isArray(message) ? message : [message]
    if (message.length > 0) {
      messagesArray.map((msg) => toast(msg, { icon: 'ℹ️' }))
    }
    dispatch({ type: REMOVE_MESSAGE })
  }, [dispatch, message])

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-3xl font-bold text-center my-4">Müşteri Paneli</h1>
      <div className="flex justify-center space-x-4 mb-2">
        <button
          onClick={() => setActiveTab('calendar')}
          className={`py-2 px-4 rounded text-lg transition duration-200 ${
            activeTab === 'calendar'
              ? 'bg-green-600 text-white'
              : 'bg-gray-200 text-gray-800'
          }`}
        >
          📅Randevu Takvimi
        </button>
        <button
          onClick={() => setActiveTab('create')}
          className={`py-2 px-4 rounded text-lg transition duration-200 ${
            activeTab === 'create'
              ? 'bg-indigo-600 text-white'
              : 'bg-gray-200 text-gray-800'
          }`}
        >
          ✍🏻Randevu Al
        </button>
        <button
          onClick={() => setActiveTab('view')}
          className={`py-2 px-4 rounded text-lg transition duration-200 ${
            activeTab === 'view'
              ? 'bg-cyan-600 text-white'
              : 'bg-gray-200 text-gray-800'
          }`}
        >
          📜Randevumu Görüntüle
        </button>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md">
        {activeTab === 'calendar' ? (
          <Calendar />
        ) : activeTab === 'create' ? (
          <AppointmentForm />
        ) : (
          <CustomerAppointmentList onCancel={handleCancel} />
        )}
      </div>
    </div>
  )
}

export default CustomerDashboard
