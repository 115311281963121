import React from 'react'

const HairStylesGrid = () => {
  const styles = [
    {
      name: 'CLASSIC CUT',
      image:
        'https://images.pexels.com/photos/1704488/pexels-photo-1704488.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260' // Resim yolunu buraya koyacaksın
    },
    {
      name: 'POMPADOUR',
      image:
        'https://images.pexels.com/photos/3998404/pexels-photo-3998404.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
      name: 'FADE',
      image:
        'https://images.pexels.com/photos/3992875/pexels-photo-3992875.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
      name: 'BUZZ CUT',
      image:
        'https://images.pexels.com/photos/14983798/pexels-photo-14983798.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
      name: 'CUZZ CUT',
      image:
        'https://images.pexels.com/photos/769690/pexels-photo-769690.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
      name: 'MOHAWK',
      image:
        'https://images.pexels.com/photos/3992874/pexels-photo-3992874.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    }
  ]

  return (
    <div className="min-h-screen flex items-center justify-center shadow-2xl rounded-3xl">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 max-w-8xl p-12 rounded-3xl shadow-custom-equal-2">
        {styles.map((style, index) => (
          <div
            key={index}
            className="relative bg-zinc-800 p-4 shadow-custom-equal overflow-hidden transform hover:scale-105 transition-transform duration-300 rounded-3xl"
          >
            <img
              src={style.image}
              alt={style.name}
              className="w-full h-72 object-cover rounded-md mb-4"
            />
            <div className="absolute bottom-0 left-0 right-0 px-4 py-5 bg-zinc-800 bg-opacity-80 shadow-xl">
              <h4 className=" text-shadow-outline text-lg font-semibold tracking-wide text-center text-neutral-50 drop-shadow-xl">
                {style.name}
              </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HairStylesGrid
