export const ADMIN_GET_APPOINTMENTS_REQUEST = 'ADMIN_GET_APPOINTMENTS_REQUEST'
export const ADMIN_GET_APPOINTMENTS_SUCCESS = 'ADMIN_GET_APPOINTMENTS_SUCCESS'
export const ADMIN_GET_APPOINTMENTS_FAILURE = 'ADMIN_GET_APPOINTMENTS_FAILURE'
export const ADMIN_CANCEL_APPOINTMENT_REQUEST =
  'ADMIN_CANCEL_APPOINTMENT_REQUEST'
export const ADMIN_CANCEL_APPOINTMENT_SUCCESS =
  'ADMIN_CANCEL_APPOINTMENT_SUCCESS'
export const ADMIN_CANCEL_APPOINTMENT_FAILURE =
  'ADMIN_CANCEL_APPOINTMENT_FAILURE'
export const ADMIN_DELETE_APPOINTMENT_REQUEST =
  'ADMIN_DELETE_APPOINTMENT_REQUEST'
export const ADMIN_DELETE_APPOINTMENT_SUCCESS =
  'ADMIN_DELETE_APPOINTMENT_SUCCESS'
export const ADMIN_DELETE_APPOINTMENT_FAILURE =
  'ADMIN_DELETE_APPOINTMENT_FAILURE'

export const ADMIN_GET_CUSTOMERS_REQUEST = 'ADMIN_GET_CUSTOMERS_REQUEST'
export const ADMIN_GET_CUSTOMERS_SUCCESS = 'ADMIN_GET_CUSTOMERS_SUCCESS'
export const ADMIN_GET_CUSTOMERS_FAILURE = 'ADMIN_GET_CUSTOMERS_FAILURE'
export const ADMIN_UPDATE_CUSTOMER_REQUEST = 'ADMIN_UPDATE_CUSTOMER_REQUEST'
export const ADMIN_UPDATE_CUSTOMER_SUCCESS = 'ADMIN_UPDATE_CUSTOMER_SUCCESS'
export const ADMIN_UPDATE_CUSTOMER_FAILURE = 'ADMIN_UPDATE_CUSTOMER_FAILURE'
export const ADMIN_DELETE_CUSTOMER_REQUEST = 'ADMIN_DELETE_CUSTOMER_REQUEST'
export const ADMIN_DELETE_CUSTOMER_SUCCESS = 'ADMIN_DELETE_CUSTOMER_SUCCESS'
export const ADMIN_DELETE_CUSTOMER_FAILURE = 'ADMIN_DELETE_CUSTOMER_FAILURE'

export const ADMIN_GET_EMPLOYEES_REQUEST = 'ADMIN_GET_EMPLOYEES_REQUEST'
export const ADMIN_GET_EMPLOYEES_SUCCESS = 'ADMIN_GET_EMPLOYEES_SUCCESS'
export const ADMIN_GET_EMPLOYEES_FAILURE = 'ADMIN_GET_EMPLOYEES_FAILURE'
export const ADMIN_ADD_EMPLOYEE_REQUEST = 'ADMIN_ADD_EMPLOYEE_REQUEST'
export const ADMIN_ADD_EMPLOYEE_SUCCESS = 'ADMIN_ADD_EMPLOYEE_SUCCESS'
export const ADMIN_ADD_EMPLOYEE_FAILURE = 'ADMIN_ADD_EMPLOYEE_FAILURE'
export const ADMIN_UPDATE_EMPLOYEE_REQUEST = 'ADMIN_UPDATE_EMPLOYEE_REQUEST'
export const ADMIN_UPDATE_EMPLOYEE_SUCCESS = 'ADMIN_UPDATE_EMPLOYEE_SUCCESS'
export const ADMIN_UPDATE_EMPLOYEE_FAILURE = 'ADMIN_UPDATE_EMPLOYEE_FAILURE'
export const ADMIN_DELETE_EMPLOYEE_REQUEST = 'ADMIN_DELETE_EMPLOYEE_REQUEST'
export const ADMIN_DELETE_EMPLOYEE_SUCCESS = 'ADMIN_DELETE_EMPLOYEE_SUCCESS'
export const ADMIN_DELETE_EMPLOYEE_FAILURE = 'ADMIN_DELETE_EMPLOYEE_FAILURE'
