import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAppointment } from '../actions/appointmentActions'

const AppointmentForm = () => {
  const dispatch = useDispatch()
  const { employees = [] } = useSelector((state) => state.employeeReducer || {})
  const { user } = useSelector((state) => state.authReducer)
  const { loading } = useSelector((state) => state.appointmentReducer)
  const [selectedEmployee, setSelectedEmployee] = useState('')
  const [date, setDate] = useState('')
  const [timeSlot, setTimeSlot] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (selectedEmployee && date && timeSlot) {
      dispatch(
        createAppointment({
          customer: user._id,
          employee: selectedEmployee,
          date,
          timeSlot
        })
      )
    }
  }

  return (
    <div className="max-w-md mx-auto bg-white p-8 rounded-xl shadow-md space-y-4">
      <h2 className="text-2xl font-bold text-center text-indigo-500">
        Randevu Formu
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-lg font-medium text-gray-700">
            <span className="text-xl mr-1">👥</span>Çalışan Seçin
          </label>
          <select
            value={selectedEmployee}
            onChange={(e) => setSelectedEmployee(e.target.value)}
            required
            className="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-75 focus:border-indigo-500 transition duration-300 ease-in-out transform hover:scale-105"
          >
            <option value="" disabled>
              Bir çalışan seçin
            </option>
            {employees && employees.length > 0 ? (
              employees.map((employee) => (
                <option key={employee._id} value={employee._id}>
                  {employee.firstName} {employee.lastName}
                </option>
              ))
            ) : (
              <option value="" disabled>
                Çalışan bulunamadı
              </option>
            )}
          </select>
        </div>
        <div>
          <label className="block text-lg font-medium text-gray-700">
            <span className="text-xl mr-1">📅</span>Tarih Seçin
          </label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-75 focus:border-indigo-500 transition duration-300 ease-in-out transform hover:scale-105"
            onClick={(e) => e.target.showPicker()}
          />
        </div>
        <div>
          <label className="block text-lg font-medium text-gray-700">
            <span className="text-xl mr-1">🕒</span>Zaman Dilimi Seçin
          </label>
          <select
            value={timeSlot}
            onChange={(e) => setTimeSlot(e.target.value)}
            required
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-75 focus:border-indigo-500 transition duration-300 ease-in-out transform hover:scale-105"
          >
            <option value="" disabled>
              Zaman Dilimi Seçin
            </option>
            <option value="00:00-00:30">00:00-00:30</option>
            <option value="00:30-01:00">00:30-01:00</option>
            <option value="01:00-01:30">01:00-01:30</option>
            <option value="01:30-02:00">01:30-02:00</option>
            <option value="02:00-02:30">02:00-02:30</option>
            <option value="02:30-03:00">02:30-03:00</option>
            <option value="03:00-03:30">03:00-03:30</option>
            <option value="03:30-04:00">03:30-04:00</option>
            <option value="04:00-04:30">04:00-04:30</option>
            <option value="04:30-05:00">04:30-05:00</option>
            <option value="05:00-05:30">05:00-05:30</option>
            <option value="05:30-06:00">05:30-06:00</option>
            <option value="06:00-06:30">06:00-06:30</option>
            <option value="06:30-07:00">06:30-07:00</option>
            <option value="07:00-07:30">07:00-07:30</option>
            <option value="07:30-08:00">07:30-08:00</option>
            <option value="08:00-08:30">08:00-08:30</option>
            <option value="08:30-09:00">08:30-09:00</option>
            <option value="09:00-09:30">09:00-09:30</option>
            <option value="09:30-10:00">09:30-10:00</option>
            <option value="10:00-10:30">10:00-10:30</option>
            <option value="10:30-11:00">10:30-11:00</option>
            <option value="11:00-11:30">11:00-11:30</option>
            <option value="11:30-12:00">11:30-12:00</option>
            <option value="12:00-12:30">12:00-12:30</option>
            <option value="12:30-13:00">12:30-13:00</option>
            <option value="13:00-13:30">13:00-13:30</option>
            <option value="13:30-14:00">13:30-14:00</option>
            <option value="14:00-14:30">14:00-14:30</option>
            <option value="14:30-15:00">14:30-15:00</option>
            <option value="15:00-15:30">15:00-15:30</option>
            <option value="15:30-16:00">15:30-16:00</option>
            <option value="16:00-16:30">16:00-16:30</option>
            <option value="16:30-17:00">16:30-17:00</option>
            <option value="17:00-17:30">17:00-17:30</option>
            <option value="17:30-18:00">17:30-18:00</option>
            <option value="18:00-18:30">18:00-18:30</option>
            <option value="18:30-19:00">18:30-19:00</option>
            <option value="19:00-19:30">19:00-19:30</option>
            <option value="19:30-20:00">19:30-20:00</option>
            <option value="20:00-20:30">20:00-20:30</option>
            <option value="20:30-21:00">20:30-21:00</option>
            <option value="21:00-21:30">21:00-21:30</option>
            <option value="21:30-22:00">21:30-22:00</option>
            <option value="22:00-22:30">22:00-22:30</option>
            <option value="22:30-23:00">22:30-23:00</option>
            <option value="23:00-23:30">23:00-23:30</option>
            <option value="23:30-00:00">23:30-00:00</option>
            {/* Diğer zaman aralıkları */}
          </select>
        </div>
        <button
          type="submit"
          disabled={loading}
          className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300 ease-in-out transform hover:scale-105"
        >
          {loading ? '⏳Lütfen Bekleyin...' : 'Randevu Al'}
        </button>
      </form>
    </div>
  )
}

export default AppointmentForm
