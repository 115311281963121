import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loginUser } from '../actions/authActions'
import { REMOVE_MESSAGE } from '../types/authTypes'
import toast from 'react-hot-toast'

const LoginPage = () => {
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const {
    loading,
    message = [],
    isAuthenticated = [],
    user = []
  } = useSelector((state) => state.authReducer)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!phone || !password) {
      return toast.error('Telefon ve şifre girilmelidir.')
    }
    dispatch(loginUser({ phone, password }))
  }

  useEffect(() => {
    const messagesArray = Array.isArray(message) ? message : [message]
    if (message.length > 0) {
      messagesArray.map((msg) => toast(msg, { icon: 'ℹ️' }))
    }
    dispatch({ type: REMOVE_MESSAGE })
  }, [dispatch, message])

  const navigate = useNavigate()
  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === 'Customer') {
        navigate('/customer-dashboard')
      } else if (user.role === 'Employee') {
        navigate('/employee-dashboard')
      } else if (user.role === 'Admin') {
        navigate('/admin-dashboard')
      }
    }
  }, [isAuthenticated, user, navigate])

  return (
    <div className="bg-zinc-800 flex justify-center items-center p-8 md:p-10 min-h-[calc(100vh-500px)] lg:min-h-[calc(100vh-100px)]">
      <div className="w-full max-w-sm px-6 lg:px-10 py-8 lg:py-14 mx-auto bg-zinc-800 rounded-lg shadow-md outline outline-1 outline-charcoal">
        <h3 className="lg:mb-8 text-2xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-cyan-300">
          Hoş Geldiniz
        </h3>

        <form className="mt-6" onSubmit={handleSubmit}>
          <div>
            <input
              className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg placeholder-gray-300 focus:ring-teal-500 focus:border-teal-500"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Telefon"
            />
          </div>
          <div className="mt-4">
            <input
              className="block w-full px-4 py-2 mt-2 shadow-sm bg-zinc-900 border border-gray-600 text-white rounded-lg placeholder-gray-300 focus:ring-teal-500 focus:border-teal-500"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Şifre"
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="block w-full px-4 py-2 mt-6 text-white font-semibold tracking-wide rounded-lg bg-gradient-to-r from-teal-500 hover:from-teal-300"
          >
            {loading ? 'Giriş Yapılıyor...' : 'Giriş Yap'}
          </button>
        </form>
      </div>
    </div>
  )
}

export default LoginPage
