import React from 'react'
import ContactForm from '../components/ContactForm'

const InfoPage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center tracking-wide bg-zinc-800 p-4">
      <main className="flex-1 container mx-auto px-4 py-8">
        <section className="flex items-center justify-center text-center mb-12">
          <div className="container">
            <h2 className="text-3xl tracking-wide font-black text-neutral-100 rounded-b-3xl p-4 bg-zinc-800 drop-shadow-xl mb-4 shadow-custom-equal border-b-2 border-x-2 border-charcoal">
              HAKKIMIZDA
            </h2>
          </div>
        </section>

        <section className="mb-12">
          <div className="grid grid-cols-1 md:grid-cols-7 gap-4">
            {/* Vizyon kartı */}
            <div className="col-span-3 bg-white opacity-85 border border-gray-600 p-8 rounded-lg shadow-md">
              <h4 className="text-2xl font-black text-zinc-800 mb-2 text-center">
                Vizyon
              </h4>
              <p className="text-gray-900 text-xl">
                Erkek bakımında standartları belirleyen öncü bir marka olarak,
                Eskişehir'de ve ötesinde tanınan bir isim olmak. Sürekli gelişen
                trendleri yakından takip ederek, hizmet kalitemizi ve müşteri
                memnuniyetimizi en üst seviyede tutmak. Modern bir atmosferde,
                müşterilerimize sadece bir saç kesimi değil, bir deneyim sunmak;
                bu deneyimle erkek bakımının yeni adresi olarak akıllarda
                kalmak.
              </p>
            </div>

            {/* Orta border */}
            <div className="col-span-1 flex justify-center">
              <div className="border-r-4 border-white opacity-85"></div>
            </div>

            {/* Misyon kartı */}
            <div className="col-span-3 bg-white opacity-85 border border-gray-600 p-8 rounded-lg shadow-md">
              <h4 className="text-2xl font-black text-zinc-800 mb-2 text-center">
                Misyon
              </h4>
              <p className="text-gray-900 text-xl">
                Erkek kuaförlüğünde sanatı ve zanaatı bir araya getirerek, her
                müşterimize kendine özgü ve kusursuz bir stil sunmak. Yenilikçi
                ve yaratıcı yaklaşımlarımızla, müşterilerimizin kişisel
                imajlarını güçlendirmek ve onlara özgüven kazandırmak. Kalite,
                hijyen ve profesyonelliği ön planda tutarak, misafirlerimize
                rahat, elit ve VIP bir hizmet deneyimi yaşatmak.
              </p>
            </div>
          </div>
        </section>

        <ContactForm />
      </main>
    </div>
  )
}

export default InfoPage
