import axiosInstance from '../../utils/axiosInstance'
import {
  ADMIN_GET_EMPLOYEES_REQUEST,
  ADMIN_GET_EMPLOYEES_SUCCESS,
  ADMIN_GET_EMPLOYEES_FAILURE,
  ADMIN_ADD_EMPLOYEE_REQUEST,
  ADMIN_ADD_EMPLOYEE_SUCCESS,
  ADMIN_ADD_EMPLOYEE_FAILURE,
  ADMIN_UPDATE_EMPLOYEE_REQUEST,
  ADMIN_UPDATE_EMPLOYEE_SUCCESS,
  ADMIN_UPDATE_EMPLOYEE_FAILURE,
  ADMIN_DELETE_EMPLOYEE_REQUEST,
  ADMIN_DELETE_EMPLOYEE_SUCCESS,
  ADMIN_DELETE_EMPLOYEE_FAILURE
} from '../../types/adminTypes'

export const getEmployees = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_GET_EMPLOYEES_REQUEST })
    const { data } = await axiosInstance.get('/admin/employees')

    dispatch({ type: ADMIN_GET_EMPLOYEES_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Çalışanlar listelenirken bir hata oldu.'
    dispatch({
      type: ADMIN_GET_EMPLOYEES_FAILURE,
      payload: message
    })
  }
}

export const addEmployee = (employeeData) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_ADD_EMPLOYEE_REQUEST })
    const { data } = await axiosInstance.post('/admin/employees', employeeData)
    dispatch({ type: ADMIN_ADD_EMPLOYEE_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Çalışan eklenirken bir hata oldu.'
    dispatch({
      type: ADMIN_ADD_EMPLOYEE_FAILURE,
      payload: message
    })
  }
}

export const updateEmployee =
  (employeeId, employeeData) => async (dispatch) => {
    try {
      dispatch({ type: ADMIN_UPDATE_EMPLOYEE_REQUEST })
      const { data } = await axiosInstance.patch(
        `/admin/employees/${employeeId}`,
        employeeData
      )
      dispatch({ type: ADMIN_UPDATE_EMPLOYEE_SUCCESS, payload: data })
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'Çalışan güncellenirken bir hata oldu.'
      dispatch({
        type: ADMIN_UPDATE_EMPLOYEE_FAILURE,
        payload: message
      })
    }
  }

export const deleteEmployee = (employeeId) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_DELETE_EMPLOYEE_REQUEST })
    await axiosInstance.delete(`/admin/employees/${employeeId}`)
    dispatch({ type: ADMIN_DELETE_EMPLOYEE_SUCCESS, payload: employeeId })
  } catch (error) {
    const message =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Çalışan silinirken bir hata oldu.'
    dispatch({
      type: ADMIN_DELETE_EMPLOYEE_FAILURE,
      payload: message
    })
  }
}
