import axiosInstance from '../utils/axiosInstance'

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_MESSAGE,
  REMOVE_MESSAGE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE
} from '../types/authTypes'

export const registerUser = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_REQUEST })

    const { data } = await axiosInstance.post('/auth/register', userData)
    console.log(data, 'BURASI authAction.js registerUser')
    dispatch({
      type: REGISTER_SUCCESS,
      payload: data.token
    })

    dispatch({
      type: SET_MESSAGE,
      payload:
        data.message || 'Kayıt başarılı! Otomatik olarak giriş yapılıyor.'
    })
    setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)

    localStorage.setItem('myToken', data.token)
  } catch (error) {
    dispatch({
      type: REGISTER_FAILURE,
      payload: error.response?.data?.errors || ['Kayıt başarısız.']
    })

    if (error.response?.data?.errors) {
      dispatch({
        type: SET_MESSAGE,
        payload: error.response.data.errors[0].msg
      })
    }
    setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)
  }
}

export const loginUser =
  ({ phone, password }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST })

      const { data } = await axiosInstance.post('/auth/login', {
        phone,
        password
      })

      dispatch({
        type: LOGIN_SUCCESS,
        payload: data.token
      })

      dispatch({
        type: SET_MESSAGE,
        payload: data.message || 'Giriş başarılı!'
      })
      setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)

      localStorage.setItem('myToken', data.token)
    } catch (error) {
      dispatch({
        type: LOGIN_FAILURE,
        payload: error.response?.data?.errors || 'Giriş başarısız.'
      })

      if (error.response?.data?.errors) {
        dispatch({
          type: SET_MESSAGE,
          payload: error.response.data.errors[0].msg
        })
      }
      setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)
    }
  }

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('myToken')
  dispatch({ type: LOGOUT })

  setTimeout(() => dispatch({ type: REMOVE_MESSAGE }), 1000)
}

export const setCurrentUser = (token) => (dispatch) => {
  dispatch({ type: LOGIN_SUCCESS, payload: token })
}
